import { render, staticRenderFns } from "./selectCashBoxes.vue?vue&type=template&id=495b4165&scoped=true&"
import script from "./selectCashBoxes.vue?vue&type=script&lang=js&"
export * from "./selectCashBoxes.vue?vue&type=script&lang=js&"
import style0 from "./selectCashBoxes.vue?vue&type=style&index=0&id=495b4165&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "495b4165",
  null
  
)

export default component.exports