/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import {loginUser} from '@/http/requests/auth'
import { loading } from '@/prototypes/u/loading'
import { cookie } from '@/prototypes/u/cookie'
import { getAssetsUrl } from "../../prototypes/u/other";

export default {
  loginUser ({commit}, payload) {
    return new Promise((resolve, reject) => {
      loginUser(payload.user).then((response) => {
        if (!response.data.user.avatar) response.data.user.avatar = response.data.user.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets//images/woman-avatar.png')
        else response.data.user.avatar = `https://tenix.useral.com/api/${  response.data.user.avatar}`

        // Set accessToken
        commit('SET_ACCESS_TOKEN', {token: response.data.token, expired_at: response.data.expired_at})

        // Update User Info
        commit('UPDATE_USER_INFO', response.data.user)

        // Set bearer token in axios
        commit('SET_BEARER', response.data.token)


        if (JSON.parse(response.data.user.browser_setting)) {
          const tableSettings = JSON.parse(response.data.user.browser_setting)
          if (tableSettings.hasOwnProperty('tables')) {
            delete tableSettings.tables.payload
          }
          // Set User Browser Settings
          if (tableSettings) {
            commit('table/SET_USER_BROWSER_SETTING', tableSettings || {}, {root: true})
            if (tableSettings.theme) {
              commit('UPDATE_THEME', {value: tableSettings.theme} || {}, {root: true})
            }
          }
        }

        // Close animation if passed as payload
        setTimeout(() => {
          if (payload.closeAnimation) payload.closeAnimation()
        }, 1500)

        resolve(response)
        // payload.notify({
        //   time: 2500,
        //   title: i18n('login.notification.login.title'),
        //   text: i18n('login.notification.login.message'),
        //   iconPack: 'feather',
        //   icon: 'icon-log-in',
        //   color: 'success'
        // })
      }).catch((error) => {

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        payload.notify({
          time: 2500,
          title: 'خطا',
          text: error.response && error.response.data ? error.response.data.message : error.response.data,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        reject(error)
      })
    })
  },

  updateUserInfo ({commit}, user) {
    if (user.avatar) {
      user.smallAvatar = getAssetsUrl(user.avatar, 'h100,w100')
      user.avatar = getAssetsUrl(user.avatar)
    }

    commit('UPDATE_USER_INFO', user)
  },

  updateUserPermission ({commit}, permission) {
    commit('UPDATE_USER_PERMISSION', permission)
  },

  updateUserFinancialYear ({commit}, financialYear) {
    commit('UPDATE_USER_FINANCIAL_YEAR', financialYear)
  },

  setAccessToken ({commit}) {
    let accessToken = localStorage.getItem('accessToken') || cookie.get('accessToken')
    commit('SET_BEARER', accessToken)
  },

  logout ({commit}) {
    commit('LOGOUT_USER')
    location.reload()
    loading.hide()
  },

  noAccessUser ({commit}) {
    commit('NO_ACCESS_USER')
    location.reload()
    loading.hide()
  }
}
