// =============================================================================
// USERS PAGES LAYOUTS
// =============================================================================

export default {
  path: '/users',
  component: () => import('../../views/admin/users/index.vue'),
  children: [
    {
      path: '/',
      name: 'users',
      component: () => import(/* webpackChunkName: "users" */ '../../views/admin/users/list/usersList.vue'),
      meta: {
        permission: 'user.show',
        pageTitle: 'اشخاص',
        pageTitleI18n: 'users.list.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'usersTrash',
      component: () => import(/* webpackChunkName: "usersTrash" */ '../../views/admin/users/trash/usersTrash.vue'),
      meta: {
        permission: 'user.delete',
        pageTitle: 'سطل زباله اشخاص',
        pageTitleI18n: 'users.trash.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertUser',
      component: () => import(/* webpackChunkName: "insertUser" */ '../../views/admin/users/insert/insertUser.vue'),
      meta: {
        permission: 'user.create',
        pageTitle: 'افزودن شخص',
        pageTitleI18n: 'users.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      component: () => import('../../views/admin/users/user/index.vue'),
      children: [
        {
          path: '/',
          component: () => import(/* webpackChunkName: "user" */ '../../views/admin/users/user/profile/userProfile'),
          name: 'user',
          meta: {
            permission: 'user.show',
            pageTitle: 'شخص',
            pageTitleI18n: 'users.profile.title'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'editUser',
          component: () => import(/* webpackChunkName: "editUser" */ '../../views/admin/users/edit/editUser.vue'),
          meta: {
            permission: 'user.update',
            pageTitle: ',ویرایش اطلاعات شخص',
            pageTitleI18n: 'users.edit.title'
            // rule: 'editor'
          }
        },
        {
          path: 'transactions',
          name: 'userTransactions',
          component: () => import(/* webpackChunkName: "userTransactions" */ '../../views/admin/users/user/transactions/userTransactions.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'گردش مالی شخص',
            pageTitleI18n: 'users.transactions.title'
            // rule: 'editor'
          }
        },
        {
          path: 'points',
          name: 'userPoints',
          component: () => import(/* webpackChunkName: "userPoints" */ '../../views/admin/users/user/points/userPoints.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'امتیاز شخص',
            pageTitleI18n: 'users.points.title'
            // rule: 'editor'
          }
        },
        {
          path: 'max-debt',
          name: 'userMaxDebt',
          component: () => import(/* webpackChunkName: "userTransactions" */ '../../views/admin/users/user/maxDebt/setUserMaxDebt.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'گردش مالی شخص',
            pageTitleI18n: 'users.transactions.title'
            // rule: 'editor'
          }
        },
        {
          path: 'message',
          name: 'sendMessageToUser',
          component: () => import(/* webpackChunkName: "sendMessageToUser" */ '../../views/admin/users/user/message/sendMessage.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'گردش مالی شخص',
            pageTitleI18n: 'smsMessageInput.title'
            // rule: 'editor'
          }
        },
        {
          path: 'transactions/:docId',
          name: 'userTransaction',
          component: () => import(/* webpackChunkName: "userTransaction" */ '../../views/admin/accountancy/documents/document/document.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'سند گردش مالی شخص',
            pageTitleI18n: 'users.transactions.documentTitle'
            // rule: 'editor'
          }
        },
        {
          path: 'addresses',
          name: 'userAddresses',
          component: () => import(/* webpackChunkName: "userAddresses" */ '../../views/admin/users/user/addresses/userAddresses.vue'),
          meta: {
            permission: 'user.show',
            pageTitle: 'شخص',
            pageTitleI18n: 'users.addresses.title'
            // rule: 'editor'
          }
        },
        {
          path: 'activities-log',
          name: 'userActivitiesLog',
          component: () => import(/* webpackChunkName: "userActivitiesLog" */ '../../views/admin/users/user/activitiesLog/userActivitiesLog.vue'),
          meta: {
            permission: 'activity_logs.show',
            pageTitle: 'لاگ های شخص',
            pageTitleI18n: 'users.logs.title'
            // rule: 'editor'
          }
        }
      ]
    },
  ]
}
