<template>
  <div class="treasury-select-users-list">
    <draggable-dynamic-table ref="selectUsersList"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="true"
                             @column:select="selectedRows = $event, checkStatus($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getUsers()"/>


    <vs-button id="selectUsers" class="useral-action-button" @click="$emit('selected', selectedRows)"/>
  </div>
</template>

<script>
import {getUsers} from '@/http/requests/users/users'
import axios from "axios";

export default {
  name: 'selectUsers',
  data () {
    return {
      checkStatusTimer: 0,
      selectedRows: [],
      insertPromptStatus: false,
      loadingTimer: 0,
      selectedCashBoxes: [],
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'address',
          i18n: 'users.select.table.header.address',
          width: '150px',
          minWidth: 150,
          filter: true,
          filterType: 'checkbox',
          filterTypes: []
        },
        {
          field: 'maxDebt',
          i18n: 'users.select.table.header.maxDebt',
          hideCurrency: true,
          align: 'right',
          width: '130px',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ]
        },
        {
          field: 'balance',
          i18n: 'users.select.table.header.balance',
          hideCurrency: true,
          permission: 'user.show_transactions',
          align: 'right',
          width: '130px',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          footer: {
            // type: 'auto-sum',
            // textType: 'price'
          }
        },
        {
          field: 'accessGroup',
          i18n: 'users.select.table.header.accessGroup',
          width: '120px',
          minWidth: 120,
          align: 'center',
          // sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'phone_number',
          i18n: 'users.select.table.header.phoneNumber',
          width: '130px',
          minWidth: 130,
          align: 'center',
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'company',
          i18n: 'users.select.table.header.company',
          width: '100px',
          minWidth: 100,
          sortable: true,
          // locked: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'users.select.table.header.name',
          align: 'left',
          ellipsis: false,
          width: '250px',
          minWidth: 250,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'registryDate',
          i18n: 'users.select.table.header.registryDate',
          width: '150px',
          minWidth: 150,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'date',
          filterRange: true
        },
        {
          field: 'row',
          i18n: 'users.select.table.header.row',
          width: '70px',
          minWidth: 70,
          align: 'center',
          fixed: true,
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        },
        {
          field: 'select',
          i18n: 'users.select.table.header.select',
          width: '50px',
          minWidth: 50,
          align: 'center',
          selectable: true,
          multiSelect: this.multiSelect,
          locked: true
        }
      ],
      data: [],
      page: 1,
      filters: {},
      sorts: ['order[0]=created_at,desc'],
      endedList: false
    }
  },
  props: {
    multiSelect: {
      type: Boolean,
      default: () => { return true }
    },
    openingBalance: {
      type: String,
      default: ''
    },
    customer: {
      type: Boolean,
      default: () => { return false }
    },
    supplier: {
      type: Boolean,
      default: () => { return false }
    },
    defaultFilter: {
      type: Object,
      default: () => { return {} }
    }
  },
  created () {

    if (this.defaultFilter.length > 0) {
      this.defaultFilter.forEach(item => {
        this.filters.push(item)
      })
    }

    if (this.openingBalance) {
      this.filters.openingInventory = `${this.openingBalance}`
    }

    if (this.customer) {
      this.filters.customer = 'true'
    }

    if (this.supplier) {
      this.filters.supplier = 'true'
    }

    this.getUsers()
  },
  methods: {
    checkStatus (selected) {
      if (!this.multiSelect) {
        clearTimeout(this.checkStatusTimer)
        this.checkStatusTimer = setTimeout(() => {
          selected.forEach((select) => {
            if (select.show) this.$emit('selected', this.selectedRows)
          })
        }, 500)
      }
    },
    getUsers () {
      if (!this.endedList) {
        if (this.$refs.usersListTable && this.data.length === 0) {
          this.$refs.usersListTable.loadMoreStatus = 'FirstLoad'
        } else if (this.$refs.usersListTable && this.data.length > 0) {
          this.$refs.usersListTable.loadMoreStatus = 'Loading'
        }

        getUsers(this.page, this.filters, this.sorts).then((res) => {
          this.endedList = res.data.data.length === 0

          res.data.data.forEach((user) => {
            const addresses = []
            user.addresses.forEach((address) => {
              if (address.country && address.province) {
                addresses.push(this.$t(`countries.${address.country.slug || ''}.provinces.${address.province.slug || ''}`))
              }
            })

            /*let gender = user.gender === 1 ? this.$t('users.types.man') : this.$t('users.types.woman')*/
            this.data.push({
              id: user.id,
              row: this.data.length + 1,
              registryDate: this.$u.convertUtcToLocale(user.created_at, this.$u.locale.validator('moment.date')),
              phone_number: {
                value: user.phone_number,
                classes: this.$vs.rtl ? 'rtl' : 'ltr'
              },
              name: /*gender + ' ' +*/ `${user.name} ${user.family}`,
              company: user.company ? user.company : '',
              group: user.group ? user.group : '',
              accessGroup: user.role && user.role.name ? user.role.name : '-',
              balance: {
                type: 'price',
                value: user.balance ? Math.abs(user.balance) : 0,
                classes: user.balance > 0 ? 'useral-text-danger' : 'useral-text-success'
              },
              maxDebt: {
                type: 'price',
                value: user.max_debt ? user.max_debt : 0
              },
              address: [...new Set(addresses)].join(', ')
            })
          })

          this.page = res.data.pagination.current_page + 1
          if (res.data.pagination.current_page === 1) {
            const row_index = this.columnsLabel.map((e) => {
              return e.field
            }).indexOf('row')
            this.columnsLabel[row_index].footer.value = res.data.pagination.total
          }

          const address_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('address')
          if (address_index > -1) {
            if (this.columnsLabel[address_index].filterTypes.length === 0) {
              if (res.data.extra.filters.provinces) {
                this.setProvinceFilterOptions(res.data.extra.filters.provinces)
              }
            }
          }
          const balance_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('balance')
          if (balance_index > -1) {
            this.columnsLabel[balance_index].footer.value = this.$u.priceFormat(res.data.extra.total.balance * -1, true)
          }

          if (this.$refs.usersListTable) this.$refs.usersListTable.loadMoreStatus = ''
        })
          .catch((error) => {
            if (this.$refs.usersListTable && !axios.isCancel(error)) this.$refs.usersListTable.loadMoreStatus = 'Danger'
          })
      }
    },
    setProvinceFilterOptions (provinces) {
      const address_index = this.columnsLabel.map((e) => {
        return e.field
      }).indexOf('address')
      if (address_index > -1) {
        this.columnsLabel[address_index].filterTypes = this.selectedFilters && this.selectedFilters.province ? this.selectedFilters.province.search : []
        const existProvincesId = this.columnsLabel[address_index].filterTypes.map(elm => elm.id)
        provinces.forEach(province => {
          if (existProvincesId.indexOf(province.id) === -1) {
            this.columnsLabel[address_index].filterTypes.push({
              id: province.id,
              label: this.$t(`countries.${province.country.slug}.provinces.${province.slug}`),
              value: province.id,
              show: false
            })
          }
        })
      }
    },
    setFilters (filters) {
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'accessGroup':
          if (filters[key].search !== '') filters_list.role = `${filters[key].search}`
          break

        case 'balance':
          if (filters[key].search !== '') filters_list.balance = `${  this.$u.parseFloat(filters[key].search) * -1  },${  filters[key].type.id}`
          break

        case 'maxDebt':
          if (filters[key].search !== '') filters_list.max_debt = `${filters[key].search},${filters[key].type.id}`
          break

          case 'address':
            if (filters[key].search.length) {
              const province_id = filters[key].search.map(elm => {
                return elm.show ? elm.id : null
              }).filter(elm => {
                return elm !== null
              }).join(this.$u.globalData.filterSeperator)

              if (province_id !== '') filters_list.province = `${  province_id}`
            }
            break

        case 'phone_number':
          if (filters[key].search !== '') filters_list.phone_number = `${filters[key].search}`
          break

        case 'company':
          if (filters[key].search !== '') filters_list.company = `${filters[key].search}`
          break

        case 'name':
          if (filters[key].search !== '') filters_list.full_name = `${filters[key].search}`
          break

        case 'registryDate':
          if (filters[key].search !== '') {
            let filter = ''
            if (Array.isArray(filters[key].search)) {
              filter = filters[key].search.map(elm => this.$u.convertLocaleToUtc(elm, this.$u.locale.validator('moment.date'))).join(this.$u.globalData.filterSeperator)
            } else {
              filter = this.$u.convertLocaleToUtc(filters[key].search, this.$u.locale.validator('moment.date'))
            }
            filters_list.created_at = `${filter}`
          }
          break
        }
      })

      if (Object.values(this.defaultFilter).length > 0) {
        Object.keys(this.defaultFilter).forEach(key => {
          if (this.defaultFilter[key]) {
            filters_list[key] = this.defaultFilter[key]
          }
        })
      }

      if (this.openingBalance) {
        this.filters.openingInventory = `${this.openingBalance}`
      }

      if (this.customer) {
        filters_list.customer = 'true'
      }

      if (this.supplier) {
        filters_list.supplier = 'true'
      }

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.$nextTick(() => {
        this.getUsers()
      })
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${columns[key] ? 'desc' : 'asc'}`)
          break

          /*case "accessGroup":
            sorts_list.push("order[0]=role," + (columns[key] ? 'desc' : 'asc'))
            break;*/

        case 'maxDebt':
          sorts_list.push(`order[0]=max_debt,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'phone_number':
          sorts_list.push(`order[0]=phone_number,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'company':
          sorts_list.push(`order[0]=company,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'registryDate':
          sorts_list.push(`order[0]=created_at,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'group':
          sorts_list.push(`order[0]=group,${columns[key] ? 'desc' : 'asc'}`)
          break

        case 'gender':
          sorts_list.push(`order[0]=gender,${columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (sorts_list.length === 0) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getUsers()
      })
    }
  }
}
</script>

<style scoped>
.treasury-select-users-list {
  height: 100%;
}
</style>
