import axios from "@/http/axios";
import store from "@/store/store";
import moment from 'moment-jalaali'
import timezonesData from '@/assets/js/timezones'

export function getAssetsUrl(url, size = '') {
  // size can be [x-small, small, medium, large]
  return axios.defaults.baseURL + `${axios.defaults.baseURL.endsWith('/') ? '' : '/'}assets` + (url.startsWith('/') ? '' : '/') + url + `?t=${Math.random()}` + (size ? `&size=${size}` : '')
}

export function customParseFloat(value, digits = 4) {
  return parseFloat(parseFloat(value).toFixed(digits))
}

export function trim (value) {
  return value.replace(/\s+/g, ' ')

}

export function click (id) {
  const element = document.getElementById(id)
  if (element) element.click()
}

export function convertUtcToLocale (time, format = 'jYYYY jMM jDD HH:mm') {
  // const timezone = timezonesData[store.state.setting.clubInfo.club_locale].timezone
  // return moment.utc(time, format.split('j').join('')).utcOffset(timezone).format(format)
  return time ? (store.state.setting.clubInfo.club_locale === 'IRI' ? time.split('-').join('/') : time.split('/').join('-')) : ''
}

export function convertLocaleToUtc (time) {
  return time ? time : null
}

export function convertPersianNumberToEnglish (str) {
  let persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g]
  let arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g]

  if (typeof str === 'string') {
    for (let i = 0; i < 10; i++) {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i)
    }
  }
  return str
}

export function numberFormat(initialvalue, digitNum, blur) {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  var value = initialvalue.toString();

  // don't validate empty input
  if (value === "") { return "" }

  // original length
  var original_len = value.length;

  // initial caret position
  // var caret_pos = input.prop("selectionStart");

  // check for decimal
  if (value.indexOf(".") >= 0 && digitNum > 0) {

    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    var decimal_pos = value.indexOf(".");

    // split number by decimal point
    var left_side = value.substring(0, decimal_pos);
    var right_side = value.substring(decimal_pos);

    // add commas to left side of number
    left_side = addComma(left_side);

    // validate right side
    right_side = addComma(right_side, false);

    // On blur make sure 2 numbers after decimal
    if (blur) {
      for (let i = 0; i < digitNum; i++) {
        right_side += "0";
      }
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, digitNum);

    // join number by .
    value = (left_side || '0') + "." + right_side;

  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    value = customParseFloat(value.toString().split(',').join(''), digitNum)
    value = addComma(value);

    // final formatting
    if (blur && digitNum > 0) {
      value = (value || '0').toString() + '.'
      for (let i = 0; i < digitNum; i++) {
        value += "0";
      }
    }
  }

  // send updated string to input
  return value;

  // put caret back in the right position
  // var updated_len = input_val.length;
  // caret_pos = updated_len - original_len + caret_pos;
  // input[0].setSelectionRange(caret_pos, caret_pos);
}

export function priceFormat (val, blur = false) {  // price format
  let price = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: store.state.setting.clubInfo.club_currency_code.toUpperCase().replace('IRT', 'IRR'),
    currencyDisplay: "code"
  }).formatToParts(0).filter(x => x.type !== "currency")
    .map(x => x.value)
    .join("")
    .trim()
  if (['IRR', 'IRT'].indexOf(store.state.setting.clubInfo.club_currency_code.toUpperCase()) > -1) {
    price = parseInt(price)
  }
  const decimalPos = price.toString().indexOf('.')
  let decimalNum = 0
  if (decimalPos > -1 && !price.toString().endsWith('.')) {
    decimalNum = price.toString().split('.')[1].length
  } else {
    // return val
  }
  return numberFormat(val.toString(), decimalNum, blur)
}

export function addComma (val, hasComma = true) {
  let sign = ''

  if (val < 0)
    sign = '-'

  val = val.toString()

  if (val[0] === '00')
    val = val.substr(1, val.length - 1)

  if (hasComma) {
    if (document.dir === 'rtl') {
      return sign + val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return sign + val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  } else {
    if (document.dir === 'rtl') {
      return val.toString().replace(/\D/g, '') + sign
    } else {
      return sign + val.toString().replace(/\D/g, '')
    }
  }
}

export function addSpace (val, limit) {

  val = val.toString()

  if (val[0] === '0')
    val = val.substr(1, val.length - 1)

  if (limit && val.length > limit)
    val = val.substr(0, limit)

  val = val.toString().replace(/\D/g, '')


  let arr = []
  if (val.substr(0, 4) !== undefined)
    arr.push(val.substr(0, 4))

  if (val.substr(4, 4) !== undefined)
    arr.push(val.substr(4, 4))

  if (val.substr(8, 4) !== undefined)
    arr.push(val.substr(8, 4))

  if (val.substr(12, 4) !== undefined)
    arr.push(val.substr(12, 4))

  let copy = ''
  arr.forEach((elm) => {
    if (elm) {
      if (copy)
        copy += ' '

      copy += elm
    }
  })
  val = copy

  return val
}

/* check permissions */

export function checkPermissions (permission) {
  let permissions = store.state.auth.user.permissions || []

  if (Array.isArray(permission)) {
    let hasPermissions = 0
    // let permissions = JSON.parse(localStorage.getItem('permissions'))
    permission.forEach((permission_item) => {
      if (permissions.indexOf(permission_item) > -1) {
        hasPermissions++
      }
    })
    return hasPermissions === permission.length ? 2 : hasPermissions > 0 ? 1 : 0

  } else {
    // let permissions = JSON.parse(localStorage.getItem('permissions'))
    return permissions.indexOf(permission) > -1
  }
}

/* /check permissions */

export function time() {
  const time = store.state.helper.currentTime
  const timezone = timezonesData.data().locales[store.state.setting.clubInfo.club_locale].timezone
  return moment(time).utc().utcOffset(timezone).format('YYYY-MM-DD HH:mm:ss')
}


export function exportFormat (type) {
  let exportFormat = ''
  switch (type) {
    case 'pdf':
      exportFormat = 'pdf'
      break

    case 'excel':
      exportFormat = 'xlsx'
      break
  }
  return exportFormat
}

export function copyToClipboard (str) {
  const el = document.createElement('textarea')
  el.value = this.selectedText ? this.selectedText : str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

// auto resize overflow texts
const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight

export const resizeTextOverflow = ({ element, elements, minSize = 10, maxSize = 25, step = 1, unit = 'px' }) => {
  (elements || [element]).forEach(el => {
    let i = maxSize
    const parent = el.parentNode
    let overflow = true

    while (overflow && i > minSize) {
      el.style.fontSize = `${i}${unit}`
      overflow = isOverflown(parent)

      if (overflow) i -= step
    }

    // revert to last state where no overflow happened
    el.style.fontSize = `${i - step}${unit}`
  })
}
